import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { PageComponentRegistry } from '@/features/a-dynamic-page/components/page-component-registry-type';

import React from 'react';

import {
    AccommodationTilesV1,
    ActivityAreaTilesV1,
    ActivityNavigationV1,
    ActivitySpotSwiperV1,
    ActivityTilesV1,
    BannerV2,
    CartV1,
    CheckoutAddressFormV1,
    CheckoutAddressSelectionV1,
    CheckoutAddressV1,
    CheckoutHeaderV1,
    CheckoutParticipantsFormV1,
    CheckoutParticipantsSelectionV1,
    CheckoutParticipantsV1,
    CheckoutPaymentCreditCardFormV1,
    CheckoutPaymentCreditCardSelectionV1,
    CheckoutPaymentCreditCardV1,
    CheckoutSummaryV1,
    CityTilesV1,
    ClimateDiagramsV2,
    CommunityTilesV1,
    ContactBarV1,
    ContinueBookingV1,
    ContinueSearchV1,
    CustomerFeedbackV1,
    FilterBarV2,
    GalleryV1,
    GroupV1,
    HeadlineV1,
    HighlightHeaderV1,
    HighlightSwiperV1,
    HintV1,
    InfoBoxV1,
    KeyFactsV2,
    LandmarkTilesV1,
    LinkCtaV1,
    LoginV1,
    MapButtonStickyV1,
    MapPreviewV1,
    MediaSwiperV1,
    NavigationBarV1,
    OpeningHoursV1,
    OtherActivitiesV1,
    OverlayCtaV1,
    PieChartV1,
    PlaceHeaderV1,
    PlacesV2,
    PpsetTeaserVerticalChipsV1,
    QuickFilterV1,
    RoutesV1,
    SchoolHeaderV1,
    SchoolProductEventSwiperV1,
    SchoolProductHeaderV1,
    SchoolProductSwiperV1,
    SchoolProductTilesV2,
    SchoolTilesV2,
    ScrollToComponentCtaV1,
    SeaBannerV1,
    SeasonCalendarV2,
    ShareV1,
    SkiSlopeFactsV1,
    SkiSlopePreviewV1,
    SpotHeaderV3,
    SpotResultsV4,
    SpotSelectorV2,
    SpotTilesV3,
    SpotTilesV3Lazy,
    StandaloneImageV1,
    TextV1,
    ThirdViewBookingInfoV1,
    ThirdViewCancellationV2,
    ThirdViewContactInfoV1,
    ThirdViewELearningInfoV1,
    ThirdViewHeaderV1,
    ThirdViewOfferV1,
    ThirdViewOverlayV1,
    ThirdViewPaymentInfoV1,
    TippV1,
    TravelFormCtaV1,
    TravelFormV1,
    TrustV1,
    WindyTileV1,
    WishlistButtonV1,
} from '@/features/_index/mobile';

export default function getFromPageComponentRegistry(name: PageComponent['@type']) {
    return registry[name];
}

const registry: PageComponentRegistry = {
    AccommodationTilesV1: {
        lazyConfig: {
            disableSizeUpdate: true,
            minHeight: 100,
            scrollMarginTop: 60,
            type: 'lazy',
        },
        render: React.memo(AccommodationTilesV1),
    },
    ActivityAreaTilesV1: {
        lazyConfig: {
            minHeight: 148,
            type: 'lazy',
        },
        render: React.memo(ActivityAreaTilesV1),
    },
    ActivityNavigationV1: {
        lazyConfig: {
            disableLazyContainer: true, // fixed position
            minHeight: 65,
            type: 'eager',
        },
        render: React.memo(ActivityNavigationV1),
    },
    ActivitySpotSwiperV1: {
        lazyConfig: {
            minHeight: 269,
            type: 'eager',
        },
        render: React.memo(ActivitySpotSwiperV1),
    },
    ActivityTilesV1: {
        lazyConfig: {
            minHeight: 180,
            type: 'eager',
        },
        render: React.memo(ActivityTilesV1),
    },
    BannerV2: {
        lazyConfig: {
            minHeight: 90,
            type: 'eager',
        },
        render: React.memo(BannerV2),
    },
    CartV1: {
        lazyConfig: {
            disableLazyContainer: true,
            minHeight: 0,
            type: 'eager',
        },
        render: React.memo(CartV1),
    },
    CheckoutAddressFormV1: {
        lazyConfig: {
            minHeight: 800,
            type: 'eager',
        },
        render: React.memo(CheckoutAddressFormV1),
    },
    CheckoutAddressSelectionV1: {
        lazyConfig: {
            minHeight: 211,
            type: 'eager',
        },
        render: React.memo(CheckoutAddressSelectionV1),
    },
    CheckoutAddressV1: {
        lazyConfig: {
            minHeight: 350,
            type: 'eager',
        },
        render: React.memo(CheckoutAddressV1),
    },
    CheckoutHeaderV1: {
        lazyConfig: {
            minHeight: 52,
            type: 'eager',
        },
        render: React.memo(CheckoutHeaderV1),
    },
    CheckoutParticipantsFormV1: {
        lazyConfig: {
            minHeight: 206,
            type: 'eager',
        },
        render: React.memo(CheckoutParticipantsFormV1),
    },
    CheckoutParticipantsSelectionV1: {
        lazyConfig: {
            minHeight: 206,
            type: 'eager',
        },
        render: React.memo(CheckoutParticipantsSelectionV1),
    },
    CheckoutParticipantsV1: {
        lazyConfig: {
            minHeight: 206,
            type: 'eager',
        },
        render: React.memo(CheckoutParticipantsV1),
    },
    CheckoutPaymentCreditCardFormV1: {
        lazyConfig: {
            minHeight: 240,
            type: 'eager',
        },
        render: React.memo(CheckoutPaymentCreditCardFormV1),
    },
    CheckoutPaymentCreditCardSelectionV1: {
        lazyConfig: {
            minHeight: 240,
            type: 'eager',
        },
        render: React.memo(CheckoutPaymentCreditCardSelectionV1),
    },
    CheckoutPaymentCreditCardV1: {
        lazyConfig: {
            minHeight: 240,
            type: 'eager',
        },
        render: React.memo(CheckoutPaymentCreditCardV1),
    },
    CheckoutSummaryV1: {
        lazyConfig: {
            minHeight: 560,
            type: 'eager',
        },
        render: React.memo(CheckoutSummaryV1),
    },
    CityTilesV1: {
        lazyConfig: {
            minHeight: 240,
            type: 'lazy',
        },
        render: React.memo(CityTilesV1),
    },
    ClimateDiagramsV2: {
        lazyConfig: {
            minHeight: 336,
            type: 'lazy',
        },
        render: React.memo(ClimateDiagramsV2),
    },
    CommunityTilesV1: {
        lazyConfig: {
            minHeight: 265,
            type: 'lazy',
        },
        render: React.memo(CommunityTilesV1),
    },
    ContactBarV1: {
        lazyConfig: {
            minHeight: 35,
            type: 'eager',
        },
        render: React.memo(ContactBarV1),
    },
    ContinueBookingV1: {
        lazyConfig: {
            disableSizeUpdate: true,
            minHeight: 134,
            type: 'lazy',
        },
        render: React.memo(ContinueBookingV1),
    },
    ContinueSearchV1: {
        lazyConfig: {
            disableSizeUpdate: true,
            minHeight: 134,
            type: 'lazy',
        },
        render: React.memo(ContinueSearchV1),
    },
    CustomerFeedbackV1: {
        lazyConfig: {
            minHeight: 71,
            type: 'lazy',
        },
        render: React.memo(CustomerFeedbackV1),
    },
    FilterBarV2: {
        lazyConfig: {
            minHeight: 51,
            type: 'eager',
        },
        render: React.memo(FilterBarV2),
    },
    FilterDataV1: { render: null },
    GalleryV1: {
        lazyConfig: {
            minHeight: 253,
            type: 'eager',
        },
        render: React.memo(GalleryV1),
    },
    GroupV1: {
        lazyConfig: {
            disableLazyContainer: true, // not needed as groups will render page components
            minHeight: 0, // not used
            type: 'eager',
        },
        render: React.memo(GroupV1),
    },
    HeadlineV1: {
        lazyConfig: {
            disableLazyContainer: true,
            minHeight: 0, // is configured in headline variants
            type: 'eager',
        },
        render: React.memo(HeadlineV1),
    },
    HighlightHeaderV1: {
        lazyConfig: {
            minHeight: 51,
            type: 'eager',
        },
        render: React.memo(HighlightHeaderV1),
    },
    HighlightSwiperV1: {
        lazyConfig: {
            customApiLazyHandling: true,
            disableLazyContainer: true,
            minHeight: 0,
            type: 'lazy',
        },
        render: React.memo(HighlightSwiperV1),
    },
    HintV1: {
        lazyConfig: {
            minHeight: 64,
            type: 'eager',
        },
        render: React.memo(HintV1),
    },
    InfoBoxV1: {
        lazyConfig: {
            minHeight: 40,
            type: 'eager',
        },
        render: React.memo(InfoBoxV1),
    },
    KeyFactsV2: {
        lazyConfig: {
            minHeight: 15,
            type: 'eager',
        },
        render: React.memo(KeyFactsV2),
    },
    LandmarkTilesV1: {
        lazyConfig: {
            minHeight: 195,
            type: 'lazy',
        },
        render: React.memo(LandmarkTilesV1),
    },
    LinkCtaV1: {
        lazyConfig: {
            minHeight: 100,
            type: 'lazy',
        },
        render: React.memo(LinkCtaV1),
    },
    LoginV1: {
        lazyConfig: {
            disableLazyContainer: true, // external web component
            minHeight: 0, // not used
            type: 'eager',
        },
        render: React.memo(LoginV1),
    },
    MapButtonStickyV1: {
        lazyConfig: {
            disableLazyContainer: true, // sticky
            minHeight: 37,
            type: 'eager',
        },
        render: React.memo(MapButtonStickyV1),
    },
    MapPreviewV1: {
        lazyConfig: {
            minHeight: 90,
            type: 'lazy',
        },
        render: React.memo(MapPreviewV1),
    },
    MediaSwiperV1: {
        lazyConfig: {
            minHeight: 90,
            type: 'eager',
        },
        render: React.memo(MediaSwiperV1),
    },
    NavigationBarV1: {
        lazyConfig: {
            minHeight: 55,
            type: 'eager',
        },
        render: React.memo(NavigationBarV1),
    },
    OpeningHoursV1: {
        lazyConfig: {
            minHeight: 38,
            type: 'eager',
        },
        render: React.memo(OpeningHoursV1),
    },
    OtherActivitiesV1: {
        lazyConfig: {
            minHeight: 112,
            type: 'lazy',
        },
        render: React.memo(OtherActivitiesV1),
    },
    OverlayCtaV1: {
        lazyConfig: {
            minHeight: 78,
            type: 'lazy',
        },
        render: React.memo(OverlayCtaV1),
    },
    PieChartV1: {
        lazyConfig: {
            minHeight: 0,
            type: 'eager',
        },
        render: React.memo(PieChartV1),
    },
    PlaceHeaderV1: {
        lazyConfig: {
            minHeight: 51,
            type: 'eager',
        },
        render: React.memo(PlaceHeaderV1),
    },
    PlacesV2: {
        lazyConfig: {
            minHeight: 265,
            type: 'lazy',
        },
        render: React.memo(PlacesV2),
    },
    PpsetTeaserVerticalChipsV1: {
        lazyConfig: {
            disableLazyContainer: true,
            minHeight: 25,
            type: 'eager',
        },
        render: React.memo(PpsetTeaserVerticalChipsV1),
    },
    QuickFilterV1: {
        lazyConfig: {
            minHeight: 44,
            type: 'eager',
        },
        render: React.memo(QuickFilterV1),
    },
    RoutesV1: {
        lazyConfig: {
            minHeight: 317,
            type: 'eager',
        },
        render: React.memo(RoutesV1),
    },
    SchoolHeaderV1: {
        lazyConfig: {
            minHeight: 64,
            type: 'eager',
        },
        render: React.memo(SchoolHeaderV1),
    },
    SchoolProductEventSwiperV1: {
        lazyConfig: {
            minHeight: 0,
            type: 'lazy',
        },
        render: React.memo(SchoolProductEventSwiperV1),
    },
    SchoolProductHeaderV1: {
        lazyConfig: {
            minHeight: 64,
            type: 'eager',
        },
        render: React.memo(SchoolProductHeaderV1),
    },
    SchoolProductSwiperV1: {
        lazyConfig: {
            minHeight: 0,
            type: 'lazy',
        },
        render: React.memo(SchoolProductSwiperV1),
    },
    SchoolProductTilesV2: {
        lazyConfig: {
            minHeight: 236,
            type: 'eager',
        },
        render: React.memo(SchoolProductTilesV2),
    },
    SchoolTilesV2: {
        lazyConfig: {
            minHeight: 207,
            type: 'lazy',
        },
        render: React.memo(SchoolTilesV2),
    },
    ScrollToComponentCtaV1: {
        lazyConfig: {
            disableLazyContainer: true, // fixed position
            minHeight: 28,
            type: 'eager',
        },
        render: React.memo(ScrollToComponentCtaV1),
    },
    SeaBannerV1: {
        lazyConfig: {
            minHeight: 280,
            type: 'eager',
        },
        render: React.memo(SeaBannerV1),
    },
    SeasonCalendarV2: {
        lazyConfig: {
            minHeight: 62,
            type: 'eager',
        },
        render: React.memo(SeasonCalendarV2),
    },
    ShareV1: {
        lazyConfig: {
            minHeight: 34,
            type: 'lazy',
        },
        render: React.memo(ShareV1),
    },
    SkiSlopeFactsV1: {
        lazyConfig: {
            minHeight: 90,
            type: 'lazy',
        },
        render: React.memo(SkiSlopeFactsV1),
    },
    SkiSlopePreviewV1: {
        lazyConfig: {
            minHeight: 90,
            type: 'lazy',
        },
        render: React.memo(SkiSlopePreviewV1),
    },
    SpotHeaderV3: {
        lazyConfig: {
            minHeight: 51,
            type: 'eager',
        },
        render: React.memo(SpotHeaderV3),
    },
    SpotResultsV4: {
        lazyConfig: {
            disableLazyContainer: true, // bottomSheet
            minHeight: 900,
            type: 'eager',
        },
        render: React.memo(SpotResultsV4),
    },
    SpotSelectorV2: {
        lazyConfig: {
            minHeight: 26,
            type: 'eager',
        },
        render: React.memo(SpotSelectorV2),
    },
    SpotTilesV3: {
        lazyConfig: {
            minHeight: 335,
            renderLazy: React.memo(SpotTilesV3Lazy),
            type: 'lazy',
        },
        render: React.memo(SpotTilesV3),
    },
    StandaloneImageV1: {
        lazyConfig: {
            minHeight: 200,
            type: 'eager',
        },
        render: React.memo(StandaloneImageV1),
    },
    TextV1: {
        lazyConfig: {
            minHeight: 0, // not used
            type: 'eager',
        },
        render: React.memo(TextV1),
    },
    ThirdViewBookingInfoV1: {
        lazyConfig: {
            minHeight: 96,
            type: 'eager',
        },
        render: React.memo(ThirdViewBookingInfoV1),
    },
    ThirdViewCancellationV2: {
        lazyConfig: {
            minHeight: 98,
            type: 'eager',
        },
        render: React.memo(ThirdViewCancellationV2),
    },
    ThirdViewContactInfoV1: {
        lazyConfig: {
            minHeight: 160,
            type: 'eager',
        },
        render: React.memo(ThirdViewContactInfoV1),
    },
    ThirdViewELearningInfoV1: {
        lazyConfig: {
            minHeight: 127,
            type: 'eager',
        },
        render: React.memo(ThirdViewELearningInfoV1),
    },
    ThirdViewHeaderV1: {
        lazyConfig: {
            minHeight: 282,
            type: 'eager',
        },
        render: React.memo(ThirdViewHeaderV1),
    },
    ThirdViewOfferV1: {
        lazyConfig: {
            minHeight: 120,
            type: 'eager',
        },
        render: React.memo(ThirdViewOfferV1),
    },
    ThirdViewOverlayV1: {
        lazyConfig: {
            disableLazyContainer: true,
            minHeight: 50,
            type: 'eager',
        },
        render: React.memo(ThirdViewOverlayV1),
    },
    ThirdViewPaymentInfoV1: {
        lazyConfig: {
            minHeight: 98,
            type: 'eager',
        },
        render: React.memo(ThirdViewPaymentInfoV1),
    },
    TippV1: {
        lazyConfig: {
            minHeight: 87,
            type: 'eager',
        },
        render: React.memo(TippV1),
    },
    TravelFormCtaV1: {
        lazyConfig: {
            disableLazyContainer: true, // sticky
            minHeight: 43,
            type: 'eager',
        },
        render: React.memo(TravelFormCtaV1),
    },
    TravelFormDataV1: { render: null },
    TravelFormV1: {
        lazyConfig: {
            minHeight: 233,
            type: 'eager',
        },
        render: React.memo(TravelFormV1),
    },
    TrustV1: {
        lazyConfig: {
            minHeight: 157,
            type: 'lazy',
        },
        render: React.memo(TrustV1),
    },
    WindyTileV1: {
        lazyConfig: {
            minHeight: 427,
            type: 'lazy',
        },
        render: React.memo(WindyTileV1),
    },
    WishlistButtonV1: {
        lazyConfig: {
            minHeight: 34,
            type: 'lazy',
        },
        render: React.memo(WishlistButtonV1),
    },
};
